









import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '~/utils/nuxt3-migration'
import { MakeLogoType } from '~/models/make'
import { useMakeLogo } from '~/compositions/make/logo'

export default defineComponent({
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    logoType: {
      type: String as PropType<MakeLogoType>,
      default: MakeLogoType.CARS
    }
  },
  setup(props) {
    const { id, logoType } = toRefs(props)
    const { getMakeLogo } = useMakeLogo()
    const imgSource = computed(() => getMakeLogo(id.value, logoType.value))

    return { imgSource }
  }
})
