import { CIcon } from '~/icons/types'

export const ciAngleDown: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 16a1 1 0 0 1-.707-.293l-5-5a1 1 0 0 1 1.414-1.414L12 13.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-5 5A1 1 0 0 1 12 16Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'angle-down',
  type: 'regular'
}
