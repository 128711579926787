
import { formatNumber } from '~/utils/number'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  functional: true,
  props: {
    count: {
      type: Number,
      required: true,
      default: 0
    }
  },
  render(createElement, { props }) {
    const componentData = {
      staticClass: 'c-count'
    }
    return createElement('span', componentData, formatNumber(props.count))
  }
})
