var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-select-none"},[_c('div',{staticClass:"tw-form-input tw-border tw-border-solid tw-py-0 tw-flex tw-items-center tw-relative tw-w-full tw-text-left header",class:_vm.computedClasses,attrs:{"role":"button","title":_vm.placeholder,"aria-disabled":_vm.disabled},on:{"click":_vm.onClick}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-full"},[_c('div',{staticClass:"tw-w-full tw-min-w-0"},[_c('div',{staticClass:"tw-flex tw-items-center tw-mr-1.5 tw-w-full tw-h-full header-text"},[(_vm.$slots.headerPreText)?_c('div',{staticClass:"tw-mr-2"},[_vm._t("headerPreText")],2):(_vm.headerPreText)?_c('div',{staticClass:"pre-text"},[_vm._v("\n            "+_vm._s(_vm.headerPreText)+":\n          ")]):_vm._e(),_vm._v(" "),(_vm.selectedOptionSet.length > 0)?_c('div',{staticClass:"main-text tw-text-grey-900"},[(_vm.multiSelect)?_c('div',{staticClass:"tw-relative tw-w-full tw-flex tw-items-center tw-gap-2"},[_c('div',{ref:"multiselectOptionsContainerRef",staticClass:"tw-flex tw-flex-wrap tw-overflow-hidden tw-items-center",class:[
                  { 'tw-max-h-5 tw-gap-2': _vm.size === 'sm' },
                  { 'tw-max-h-6 tw-gap-2': _vm.size === 'md' },
                  { 'tw-max-h-8 tw-gap-3': _vm.size === 'lg' }
                ]},_vm._l((_vm.selectedOptionSet),function(option){return _c('div',{key:option.value,ref:"multiselectOptionsRefs",refInFor:true,staticClass:"tw-flex tw-items-center tw-bg-blue-100 tw-text-blue-500 tw-font-medium tw-gap-2",class:[
                    { 'tw-pointer-events-none tw-z-20': _vm.useNativeSelect },
                    {
                      'tw-text-sm tw-px-1 tw-py-0.5 tw-gap-1 tw-rounded':
                        _vm.size === 'sm'
                    },
                    {
                      'tw-text-base tw-px-1 tw-py-0.5 tw-gap-1 tw-rounded':
                        _vm.size === 'md'
                    },
                    {
                      'tw-text-lg tw-px-2 tw-py-1 tw-gap-3 tw-rounded-md':
                        _vm.size === 'lg'
                    }
                  ]},[_c('span',[_vm._v("\n                    "+_vm._s(option.text)+"\n                  ")]),_vm._v(" "),_c('button',{staticClass:"tw-text-blue-500 hover:tw-text-blue-800",class:{ 'tw-pointer-events-auto': _vm.useNativeSelect },attrs:{"type":"button","aria-label":"Remove option"},on:{"click":function($event){$event.stopPropagation();return _vm.onOptionRemoveClick(option)}}},[_c('CIcon',{attrs:{"icon":_vm.ciTimes}})],1)])}),0),_vm._v(" "),(_vm.multiselectOverflownOptionsCount > 0)?_c('div',{staticClass:"tw-bg-blue-500 tw-text-white",class:[
                  [
                    {
                      'tw-text-sm tw-px-1 tw-py-0.5 tw-rounded': _vm.size === 'sm'
                    },
                    {
                      'tw-text-base tw-px-1 tw-py-0.5 tw-rounded':
                        _vm.size === 'md'
                    },
                    {
                      'tw-text-lg tw-px-2 tw-py-1 tw-rounded-md':
                        _vm.size === 'lg'
                    }
                  ]
                ]},[_vm._v("\n                +"+_vm._s(_vm.multiselectOverflownOptionsCount)+"\n              ")]):_vm._e()]):_c('div',[(_vm.prefix)?_c('span',{staticClass:"prefix"},[_vm._v(_vm._s(_vm.prefix))]):_c('div',{staticClass:"main-text-selected tw-text-ellipsis tw-overflow-hidden tw-max-w-full",class:_vm.mainTextClasses},[_vm._v("\n                "+_vm._s(_vm.firstOption)+"\n              ")])])]):_c('div',{staticClass:"main-text"},[_vm._v("\n            "+_vm._s(_vm.placeholder)+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-ml-1"},[_c('CIcon',{staticClass:"caret-icon tw-transition-transform",class:[
            _vm.disabled ? 'tw-text-grey-600' : 'tw-text-grey-700',
            { 'tw-rotate-180': _vm.internalDropdownOpen }
          ],attrs:{"icon":_vm.ciAngleDown,"size":_vm.size === 'lg' ? 20 : 16}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }